// Original: https://dribbble.com/shots/5708399-Christmas-Collisions
// By: 𝔅𝔢𝔰𝔱𝔖𝔢𝔯𝔳𝔢𝔡𝔅𝔬𝔩𝔡 @bstsrvdbld

import { createRoot } from "react-dom/client"
import { StrictMode, Suspense } from "react"
import App from "./App"
import "./styles.css"
import { SuperFanProvider } from "./context"

let _firebaseConfig = {
  apiKey: "AIzaSyCvOLqEkE3S0K6NKMW6vIO5MjVdxJ4k0Zw",
  authDomain: "superfan-3a794.firebaseapp.com",
  projectId: "superfan-3a794",
  storageBucket: "superfan-3a794.appspot.com",
  messagingSenderId: "18328979437",
  appId: "1:18328979437:web:b6fb5190d1b2ba9445c9e4",
  measurementId: "G-9MNC1K6CRJ",
}

let _collection = "coach-my-holiday2023"
let _superfan = "coach-my-holiday2023-superfan"

createRoot(document.getElementById("root")).render(
  <SuperFanProvider
    value={{
      firebaseConfig: _firebaseConfig,
      collection: _collection,
      superfan: _superfan,
    }}>
    <App />
  </SuperFanProvider>,
)
